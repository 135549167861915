import React from "react"

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import {
  formatMarkdown,
  formatOffice,
  formatBrand,
  longformFormater,
  formatVideoField,
} from "../../../utils/previewFormaters"

import {
  contentfulClient,
  getContentfulEntryID,
} from "../../../utils/contentfulClient"

import WorkSingleComponent from "../../../content/work/WorkSingleComponent"

class NewPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
    this.entryID = getContentfulEntryID()
  }

  // componentWillMount() {
  //   this.props.startTransition()
  // }

  componentDidMount() {
    if (this.entryID) {
      contentfulClient()
        .getEntries({
          "sys.id": this.entryID,
          content_type: `work`,
          include: 10,
          limit: 1,
        })
        .then(entries => this.formatData(entries))
        .catch(err => console.log(err))
    }
  }

  formatData(entries) {
    // end transition when data is ready
    // this.sprops.endTransition()

    let entry = entries.items[0].fields
    let data = { work: null }

    if (entry) {
      data.work = {
        name: entry.name,
        subheadline: entry.subheadline,
        fullscreenHeader: entry.fullscreenHeader,
        slug: entry.slug,
        caseStudiesWorkDesign: entry.caseStudiesWorkDesign,
        launchedOn: entry.launchedOn,
        office: null,
        shortDescription: entry.shortDescription
          ? formatMarkdown(entry.shortDescription)
          : null,
        publishDate: entry.publishDate,
        office: entry.office ? formatOffice(entry.office) : null,
        brand: entry.brand ? formatBrand(entry.brand) : null,
        mainImage: entry.mainImage
          ? {
              resolutions: {
                srcWebm: entry.mainImage.fields.file.url,
                src: entry.mainImage.fields.file.url,
              },
            }
          : null,
        videoPreview: entry.videoPreview
          ? formatVideoField(entry.videoPreview)
          : null,
        videoFull: entry.videoFull ? formatVideoField(entry.videoFull) : null,
        longform: entry.longform ? longformFormater(entry.longform) : null,
        relatedWork: null,
      }

      this.setState({
        data: data,
      })
    }
  }

  render() {
    // console.log(this.state.data);
    return (
      <Layout>
        <SEO title={`Work Preview`} description={`Work Preview`} />
        <div style={{ minHeight: `100vh` }}>
          {this.state.data && (
            <WorkSingleComponent data={this.state.data} section={`work`} />
          )}
        </div>
      </Layout>
    )
  }
}

export default NewPreview